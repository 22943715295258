.home {
  font-family: Roboto;
  position: relative;
  padding-top: 70px;

  .headLayoutBox {
    padding: 100px 5% 0 5%;
    width: 100%;

    .content {
      z-index: 2;
    }

    .polygons {
      height: 1015px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #F3F9FE;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        backdrop-filter: blur(130px);
        z-index: 1;
      }

      .polygon {
        position: absolute;
        opacity: 0.7;
        z-index: -1;
      }

      .polygon_1 {
        // bottom: 400px;
        bottom: 20.83vw;
        left: 3%;
        transform: translate(-50%, 0);
        // width: 100px;
        width: 5.208vw;
        // height: 390px;
        height: 20.31vw;
        background: rgb(13, 0, 252);
        clip-path: polygon(10% 0, 100% 70%, 100% 100%, 20% 90%);
      }

      .polygon_2 {
        bottom: 0px;
        left: 22%;
        transform: translate(-50%, 0);
        // width: 1000px;
        width: 52.08vw;
        // height: 450px;
        height: 23.437vw;
        background: linear-gradient(-36deg, #e950d1, #f980d9);
        clip-path: polygon(10% 0, 100% 70%, 100% 100%, 20% 90%);
      }

      .polygon_3 {
        bottom: 0px;
        left: 70%;
        transform: translate(-50%, 0);
        // width: 1000px;
        width: 52.08vw;
        // height: 450px;
        height: 23.437vw;
        background: rgb(87, 79, 236);
        clip-path: polygon(80% 0, 100% 70%, 100% 100%, 20% 90%);
      }

      .polygon_4 {
        bottom: 20.83vw;
        right: 3%;
        transform: translate(-50%, 0);
        // width: 100px;
        width: 5.208vw;
        // height: 390px;
        height: 20.31vw;
        background: linear-gradient(-36deg, #e950d1, #f980d9);
        clip-path: polygon(10% 0, 100% 70%, 100% 100%, 20% 90%);
      }
    }
  }

  .banner_title {
    padding: 0 16px;
    text-align: center;
    font-size: 60px;
    line-height: 70px;
    font-weight: 700;
    color: #1a1b1c;

    &>span {
      opacity: 0;
    }
  }

  .banner_title_animation {
    &>span {
      animation: h1Opa 0.5s linear calc(var(--d) * 1s) forwards;
    }
  }

  .banner_desc {
    margin-top: 18px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #1a1b1c;
    line-height: 24px;

    &>span {
      opacity: 0;
      animation: h1Opa 0.5s linear calc(var(--d) * 1s) forwards;
    }
  }

  .banner_btn_box {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 38px;
    flex-direction: row;
    margin: 38px auto 0;

    .templateBtn {
      min-width: 200px;
      padding: 0 25px;
      height: 56px;
      box-sizing: border-box;
      font-size: 17px;
      font-weight: 500;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      white-space: nowrap;
      transition: all 0.3s;
      border: 1px solid #ff822c;
      color: #ffffff;
      background: #ff822c;
      flex: 1;
      position: relative;
      margin: 0 10px 0 10px;

      &:hover {
        background: #E06410;
        border-color: #E06410;
      }

      p {
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

    .scratchBtn {
      min-width: 200px;
      padding: 0 25px;
      height: 56px;
      font-size: 17px;
      font-weight: 500;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      white-space: nowrap;
      transition: all 0.3s;
      border: 1px solid #000000;
      color: #000000;
      flex: 1;
      margin: 0 10px 0 10px;

      &:hover {
        background: #FFFFFF;
        color: #000000;
        //background: #e0640f;
        border-color: #FFFFFF;
      }

      p {
        padding: 0 25px;
        display: flex;
        align-items: center;

        span {
          text-align: center;
          height: 20px;
          line-height: 20px;
        }
      }

    }
  }

  .templateBottomBtn {
    margin: 42px auto 0 auto;
    width: max-content;
    min-width: 190px;
    height: 50px;
    line-height: 50px;
    display: block;
    text-align: center;
    white-space: nowrap;
    background: #ff822c;
    border-radius: 30px;
    border: 1px solid #ff822c;
    font-size: 17px;
    font-weight: 500;
    color: #ffffff;
    transition: all 0.3s;

    &>svg {
      height: 17px;
      position: relative;
      top: 2px;
    }

    &>svg path {
      fill: #FFFFFF;
    }

    &:hover {
      background: #e0640f;
      color: #fff;
      border-color: transparent;
    }
  }

  .templateBtnSvg {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-left: 8px;
    position: relative;
    top: 1px;
  }

  .video_box {
    display: block;
    max-width: 1300px;
    margin: 54px auto 0;
    border-radius: 20px;

    .video {
      width: 100%;
      height: auto;
      border-radius: inherit;
      vertical-align: bottom;
      box-shadow: 0 30px 80px 20px rgba(34, 32, 77, 0.08);
    }
  }

  .trust_text {
    padding: 70px 0 0 0;
    font-size: 16px;
    font-weight: 500;
    color: #a5a8b0;
    text-align: center;
    //line-height: 20px;
    letter-spacing: 5px;
  }

  .logo_bbox {
    max-width: 1100px;
    margin: 20px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .trustedIconBox {
      width: max-content;
      height: 27px;
      display: flex;
      align-items: center;

      & svg {
        height: inherit;
        margin: 0 35px;
      }
    }
  }

  .templateBox {
    margin-top: 140px;

    .templateTitle {
      width: 90%;
      margin: 0 auto 35px;
      font-size: 50px;
      font-weight: 700;
      color: #1a1b1c;
      line-height: 60px;
      text-align: center;
      font-family: Roboto;

      &>span {
        background-image: linear-gradient(270deg, #3f92ff, #833ee4 45%, #ff7750 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        text-align: center;
        color: #000000;
        letter-spacing: -0.65px;
      }
    }

    .tabsLayoutBox {
      width: max-content;
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  .choicenessBox {
    margin: 140px 0;
    overflow: hidden;
  }

  .resourceLayoutBox {}

  .entranceContainer {
    width: 100%;
  }

  .featureContainer {
    max-width: 1500px;
    margin: 140px auto 0 auto;
    //padding: 0 5%;
  }

  .caseStudiesContainer {
    margin: 160px 5% 72px 5%;

    .caseStudiesTitle {
      font-size: 50px;
      font-family: Inter, Inter-Semibold;
      font-weight: bold;
      text-align: center;
      color: #000000;
      margin-bottom: 24px;
    }
  }

  .floatDomBox {
    width: 100%;
    height: max-content;
    overflow: hidden;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.10);
    transform-origin: bottom center;
    transition: all 0.5s;
    z-index: 999;
    position: sticky;
    bottom: 0;
  }

  .floatDomBoxHidden {
    height: 0;
    transform: translateY(100%);
  }
}

@keyframes h1Opa {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.tabBox {
  height: 52px !important;
  line-height: 50px !important;
}

.tabItem {
  padding: 0 20px;
  border-radius: 30px !important;
  border: 1px solid transparent;
  margin-left: 14px !important;
  transition: all 0.3s;
  color: #110718 !important;
  font-weight: 500 !important;
  box-sizing: border-box !important;
  font-family: Roboto !important;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 30px;
  }
}

.tabItemActive {
  color: #FF822C !important;
  border: 1px solid #ff822c !important;
}


.featureItemStyle {
  margin: 140px 0 0 0;
}

.featureItemStyleTop {}

.leftTransformStartClass {
  transform: translateX(20px);
  visibility: hidden;
  opacity: 0;
}

.rightTransformStartClass {
  transform: translateX(-20px);
  visibility: hidden;
  opacity: 0;
}

.featureTransformEndClass {
  animation: featureAnimation 0.5s linear forwards;
}

.featureWidthIconImg {
  border-radius: 20px;
}

@keyframes featureAnimation {
  to {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
  }
}


.previewBtn {
  width: 210px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  color: #FFF;
  background-color: #ff822c;
  cursor: pointer;
  border-radius: 26px;
  margin: 0 auto;
  position: relative;
  top: 26px;
  transition: all 0.3s;
  user-select: none;

  &:hover {
    background-color: #e0640f;
  }
}


.commentLayoutBox {
  max-width: 1304px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  .commentLeft {
    max-width: 636px;
    background-color: rgb(18, 51, 60);
    border-radius: 20px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    align-self: stretch;

    position: relative;

    .commentLeftBg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .commentLeftAnimationStart {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-20px);
  }

  .commentRight {
    max-width: 636px;
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.06);
    position: relative;
    align-self: stretch;

    .commentRightTopIcon {
      position: absolute;
      top: 37px;
      left: 50px;
    }
  }

  .commentRightAnimationStart {
    visibility: hidden;
    opacity: 0;
    transform: translateX(20px);
  }

  .commentAnimationEnd {
    animation: commentAnimation 0.5s linear forwards;
  }

  @keyframes commentAnimation {
    to {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }
  }
}

.commentBox {
  background: #f4f8ff;

  .commentTitle {
    max-width: 90%;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #000000;
    line-height: 64px;
    padding: 160px 0 40px 0;
    margin: 0 auto;
  }

  .commentLeftContainer {
    width: 634px;
    max-width: 636px;
    height: inherit;
    box-sizing: border-box;
    padding: 84px 55px 0 55px;

    .commentType {
      height: 60px;
      font-size: 80px;
      font-weight: bold;
      text-align: left;
      color: #ffffff;
      line-height: 60px;

    }

    .comment {
      margin-top: 17px;
      font-size: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      line-height: 32px;
      padding-bottom: 30px;
    }
  }

  .commentRightItem {
    max-width: 636px;
    height: 100%;
    min-height: 390px;
    box-sizing: border-box;
    padding: 120px 50px 37px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &>p {
      font-size: 17px;
      text-align: left;
      color: #000000;
      line-height: 28px;
    }

    &>.feedBackProvider {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;

      .feedBackProviderLeft {
        display: flex;
        align-items: center;

        img {
          width: 61px;
          height: 61px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          object-fit: cover;
        }
      }

      .feedBackProviderLeftText {
        height: 61px;
        max-width: 300px;
        font-size: 19px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &>div {
          height: 25px;
          line-height: 25px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
          color: #000000;
        }

        &>span {
          font-size: 15px;
          height: 25px;
          line-height: 25px;
          display: inline-block;
          text-align: left;
          color: rgba(0, 0, 0, 0.34);
        }
      }

      .feedBackProviderRight {
        font-size: 14px;
        text-align: right;
        color: #686c7e;
        margin-left: auto;
        display: flex;
        align-items: center;
        cursor: pointer;

        &>span {
          transition: all 0.3s;
        }

        &:hover {
          color: #1A1B1C;

          &>svg path {
            fill: #1A1B1C;
          }
        }

        &>svg {
          width: 14px;
          margin-left: 8px;
        }

        &>svg path {
          fill: #686C7E;
        }
      }
    }
  }

  .ratingSite {
    margin-top: 100px;
    padding: 60px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.swiperBox {
  width: 200vw;
  height: 200px;
  display: flex;

  &>.swiperItem {
    width: 100px;
    height: 100px;
    margin: 0 10px;
    border: 1px solid red;
  }
}

.templateItemBox {
  max-height: 200px;
  overflow: hidden;
  user-select: none;
  margin: 15px;

  &>img {
    user-select: none;
    border-radius: 6px;
  }
}

@media all and (max-width: 1700px) {
  .home {
    .featureContainer {
      margin: 140px 5% 0 5%;
    }
  }
}

@media all and (max-width: 1465px) {
  .caseStudiesContainer {
    max-width: 1110px;
    margin: 160px auto 72px auto !important;
  }
}

@media all and (max-width: 1400px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 72vw;
      }
    }
  }


  .commentLayoutBox {
    flex-direction: column;
    align-items: center;
    margin: 0 5%;

    .commentLeft {
      //max-width: 100%;
      //margin-bottom: 40px;
      margin: 0 auto 20px auto;
    }

    .commentRight {
      //max-width: 100%;
      margin: 0 auto;
    }
  }

  .commentLeftContainer {
    padding: 84px 55px 84px 55px !important;
  }
}

@media all and (max-width: 1300px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 74vw;
      }
    }

    .templateBox {
      .tabsLayoutBox {
        max-width: 90%;
      }
    }
  }
}

@media all and (max-width: 1150px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 77vw;
      }
    }
  }

  .caseStudiesContainer {
    max-width: 860px;
    margin: 160px auto 72px auto !important;
  }
}

@media all and (max-width: 1000px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 88vw;
      }
    }
  }
}

@media all and (max-width: 900px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 92vw;
      }
    }
  }

  .caseStudiesContainer {
    max-width: 416px;
    margin: 160px auto 72px auto !important;
  }
}

@media all and (max-width: 800px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 96vw;
      }
    }
  }
}

@media all and (max-width: 700px) {

  .home {
    .headLayoutBox {
      .polygons {
        height: 90vw;
      }
    }
  }

  .headLayoutBox {
    padding: 28px 20px 0 20px !important;
  }

  .banner_desc {
    font-size: 16px !important;
    color: #110718;
    line-height: 24px !important;
    margin-top: 9px;
  }

  .trust_text {
    padding: 35px 0 0 0 !important;
  }

  .banner_btn_box {
    margin-top: 16px !important;

    .templateBtn {
      height: 50px !important;
    }

    .scratchBtn {
      height: 50px !important;
    }
  }

  .banner_btn_box_reverse {
    margin-top: 16px !important;

    .templateBtn {
      height: 50px !important;
    }

    .scratchBtn {
      height: 50px !important;
    }
  }

  .video_box {
    margin: 24px auto 0 !important;
  }

  .templateBox {
    margin-top: 70px;
  }

  .tabItem {
    height: 42px !important;
    padding: 0 12px !important;
    line-height: 42px !important;
  }

  .resourceLayoutBox {
    padding: 0 20px;
  }

  .commentLayoutBox {
    flex-direction: column;
    align-items: center;
    margin: 0 20px;

    .commentLeft {
      max-width: calc(100vw - 40px);
    }

    .commentRight {
      max-width: calc(100vw - 40px);


      .commentRightTopIcon {
        top: 28px;
        left: 22px;
        width: 40px;
      }
    }
  }

  .commentBox {

    .commentLeftContainer {
      max-width: 90vw;
    }

    .commentRightItem {
      max-width: 90vw;
      padding: 90px 22px 33px 22px;
    }
  }

  .caseStudiesContainer {
    padding: 0 20px;
    margin: 70px auto 0 auto !important;

    .caseStudiesTitle {
      margin-bottom: 14px !important;
    }
  }
}

@media all and (max-width: 640px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 109.37vw;
      }
    }

    .banner_btn_box {
      flex-direction: column;
      align-items: center;
      width: max-content;
      max-width: 100%;
      margin: 0 auto;
      height: 120px;

      .templateBtn {
        font-size: 15px;
        align-self: stretch;
        margin: 5px 0 5px 0;
        width: 100%;
        min-width: 290px;
      }

      .scratchBtn {
        font-size: 15px;
        align-self: stretch;
        width: 100%;
        margin: 5px 0 5px 0;
        min-width: 290px;
      }
    }

    .banner_btn_box_reverse {
      flex-direction: column-reverse;
      align-items: center;
      width: max-content;
      max-width: 100%;
      margin: 0 auto;
      height: 94px;

      .templateBtn {
        font-size: 15px;
        align-self: stretch;
        margin: 5px 0 5px 0;
        width: 100%;
      }

      .scratchBtn {
        font-size: 15px;
        align-self: stretch;
        width: 100%;
        margin: 5px 0 5px 0;
      }
    }

    .templateBox {
      margin-top: 45px;

      .templateTitle {
        margin: 0 auto 16px !important;
      }
    }

    .templateBottomBtn {
      height: 42px;
      line-height: 42px;
      margin-top: 22px !important;
      font-size: 15px;
    }

    .choicenessBox {
      margin: 70px 0;
    }

    .ratingSite {
      margin-top: 50px;
      padding: 50px 0;
    }
  }

  .tabItem {
    font-size: 15px !important;
  }

  .featureContainer {
    margin: 0 5% !important;
  }

  .FeatureWidthIconImg {
    margin-top: 20px;
  }

  .featureItemStyle {
    margin-top: 70px !important;
    flex-direction: column !important;
  }

  .featureItemStyleTop {
    margin-top: 70px !important;
    flex-direction: column !important;
  }

  .commentLayoutBox {

    .commentLeft {
      height: max-content;
      min-height: auto;
    }
  }

  .leftTransformStartClass {
    margin-top: 20px !important;
  }

  .rightTransformStartClass {
    margin-top: 20px !important;
  }

  .commentBox {
    .commentTitle {
      padding: 70px 0 16px 0;
    }

    .commentLeftContainer {
      padding: 26px 22px 30px 22px !important;
      height: auto;
      min-height: 240px;

      .commentType {
        font-size: 40px;
      }

      .comment {
        font-size: 16px !important;
        line-height: 24px !important;
        margin-top: 10px;
      }
    }

    .commentRightItem {
      padding: 100px 22px 33px 22px;

      &>p {
        font-size: 16px !important;
        line-height: 26px !important;
      }


      .feedBackProviderLeftText {

        &>div {
          font-size: 17px;
        }

        &>span {
          font-size: 15px;
        }
      }

      &>.feedBackProvider {
        .feedBackProviderRight {
          &>span {
            display: none;
          }
        }
      }
    }
  }
}

@media all and (max-width:540px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 113vw;
      }
    }
  }
}

@media all and (max-width:500px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 123vw;
      }
    }
  }
}

@media all and (max-width:470px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 132vw;
      }
    }
  }
}

@media all and (max-width:440px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 135vw;
      }
    }
  }
}

@media all and (max-width:420px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 138vw;
      }
    }
  }
}

@media all and (max-width:415px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 140vw;
      }
    }
  }
}

@media all and (max-width:400px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 142vw;
      }
    }
  }
}

@media all and (max-width:395px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 144vw;
      }
    }
  }
}

@media all and (max-width:380px) {
  .home {
    .headLayoutBox {
      .polygons {
        height: 148vw;
      }
    }
  }
}