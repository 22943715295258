.platformList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 30px;
  flex-wrap: wrap;
  margin: 0 auto;

  .platformItem {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 360px;
    height: 77px;
    padding: 0 20px;
    background: rgba(255,255,255,0.65);
    border-radius: 12px;
    cursor: pointer;
    transition: all .3s;
    user-select: none;

    &:hover {
      background: rgba(255,255,255,1);
    }

    & > svg {
      width: 110px;
      height: 28px;
    }

    .platformRight {
      margin-left: 26px;
      flex: 1;
      overflow: hidden;

      .startBox {
        & > svg {
          width: 18px;
          height: 18px;
          margin-left: 8px;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      .Trustpilot {
        & > svg path {
          fill: #00B67A;
        }
      }

      .Facebook {
        & > svg path {
          fill: #1877F2;
        }
      }

      .Capterra {
        & > svg path {
          fill: #FF9D29;
        }
      }

      & > .text {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 13px !important;
        font-weight: normal;
        text-align: left;
        color: #000000;
        line-height: 15px;
      }
    }

    &:hover {
      box-shadow: none;
      background: rgba(255,255,255,1);
    }
  }
}

@media all and (max-width: 500px) {
  .platformList {
    gap: 14px;
    padding: 0 20px;

    .platformItem {

      &:first-child {
        margin-top: 0;
      }
    }
  }
}