@themeColor: #ff822c;
@hoverColor: #e0640f;
@black: #000000;

//.swiper {
//  width: 100%;
//  z-index: 0;
//
//  :global {
//    .swiper-pagination-bullet-active {
//      background-color: @themeColor;
//    }
//  }
//}
.contentBox {
  display: flex;
  justify-content: center;
}

.swiperSlide {
  width: max-content;
  height: inherit;
  transition-timing-function: linear !important;
}

.preBtn {
  position: absolute;
  right: calc(96px);
  top: 80px;
  z-index: 999;
  transform: translate(0, -100%);
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  //box-shadow: 0 2px 6px 0 rgb(18 15 28 / 20%);
  cursor: pointer;
  outline: 0;
  //overflow: hidden;
  border-radius: 100%;
  transition: all 0.3s;

  width: 36px;
  height: 36px;
  background: #f3f3f7;
  user-select: none;

  svg path {
    transition: all 0.3s;
    fill: #1A1B1C;
  }

  &:hover {
    background-color: #E9E9ED;
  }
}

.nextBtn {
  position: absolute;
  right: min(50px, calc(20% - 60px));
  top: 80px;
  transform: translate(0, -100%);
  z-index: 999;
}

.none {
  display: none;
}

.leftIcon {
  transform: rotate(180deg);
}

@media all and (max-width: 700px) {
  .btn {
    width: 30px;
    height: 30px;
  }

  .preBtn {
    top: 68px;
    right: 62px;
  }

  .nextBtn {
    top: 68px;
    right: 22px;
  }
}

@media all and (max-width: 600px) {
  .contentBoxNormal {
    padding: 0 5%;
  }
}