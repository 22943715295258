.layoutBox {
  max-width: 1375px;
  overflow: scroll;
  margin: 20px auto 0 auto;

  scrollbar-width: none; /* 在 Firefox 中隐藏滚动条 */
  -ms-overflow-style: none; /* 在 IE 和 Edge 中隐藏滚动条 */

  &::-webkit-scrollbar {
    display: none;
  }

  .logo_bbox {
    width: max-content;
    white-space: nowrap;
    //animation: swiperAnimation 52s linear infinite;

    .trustedIconBox {
      display: inline-block;
      width: max-content;

      &:first-child {

        & svg {
          margin: 0 34.5px 0 0;
        }
      }

      & svg {
        height: inherit;
        margin: 0 34.5px;
      }
    }
  }
}

@keyframes swiperAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media all and (max-width: 1550px) {
  .layoutBox {
    .logo_bbox {
      animation: swiperAnimation 52s linear infinite;
    }
  }
}

@media all and (max-width: 700px) {
  .layoutBox {

    .logo_bbox {
      .trustedIconBox {
        & svg {
          scale: 0.76;
        }
      }
    }
  }
}