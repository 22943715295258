.default {
    display: block;
    padding: 20px 44px;
    margin-right: 10px;
    min-width: 250px;
    background: #ff822c;
    border-radius: 30px;
    border: 1px solid #ff822c;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    transition: all 0.3s;
    white-space: nowrap;
    &:hover {
        background: #e0640f;
        border-color: #e0640f;
    }
}
