@themeColor: #ff822c;
@hoverColor: #e0640f;
@black: #000000;

.swiper {
  width: 100%;
  height: 100%;
}
.FJSwiperContainer {}

.swiperSlide {
  width: max-content;
  height: 100%;

}

.preBtn {
  position: absolute;
  left: 1px;
  top: 48%;
  z-index: 999;
  transform: translate(0, -100%);
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 2px 6px 0 rgb(18 15 28 / 20%);
  cursor: pointer;
  outline: 0;
  //overflow: hidden;
  border-radius: 100%;
  transition: all 0.3s;

  &:hover {
    border-color: #ff822c;

    svg path {
      fill: #ff822c;
    }
  }
}

.nextBtn {
  position: absolute;
  right: 1px;
  top: 48%;
  transform: translate(0, -100%);
  z-index: 999;
}

.none {
  display: none;
}

.leftIcon {
  transform: rotate(180deg);
}

.swiperPaginationBulletFJ {
  display: inline-block;
  width: 6px; /* 设置小圆点的宽度 */
  height: 6px; /* 设置小圆点的高度 */
  background-color: #ffffff;
  opacity: 0.4;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 8px;
}

.swiperPaginationBulletActiveFJ {
  display: inline-block;
  width: 40px; /* 设置小圆点的宽度 */
  height: 6px; /* 设置小圆点的高度 */
  border-radius: 3px;
  background-color: #ffffff;
  opacity: 1;
}

.horizontalBox {
  width: max-content!important;
  margin-left: 50px;
  bottom: 14%!important;
}

@media all and (max-width: 520px) {
  .horizontalBox {
    bottom: 12% !important;
    margin-left: 16px !important;
  }
}