.itemBox {
  width: 500px;
  max-Width: 500px;
  margin: 34px 0 0 25px;
  font-family: Roboto;
  overflow: hidden;

  .slot_box {
    width: 100%;
    height: 280px;
    position: relative;
    cursor: pointer;
    background: #f8f8f8;
    text-align: center;
    box-sizing: content-box;
    border-radius: 10px;
    overflow: inherit;

    //&:after {
    //  position: absolute;
    //  left: 0;
    //  top: 0;
    //  box-sizing: border-box;
    //  width: 100%;
    //  height: 100%;
    //  border: 1px solid rgba(64, 87, 109, .1);
    //  border-radius: inherit;
    //  content: "";
    //  pointer-events: none;
    //  z-index: 2;
    //}

    & > .image {
      width: max-content;
      border-radius: inherit;
      transform-origin: center center;
      z-index: 2;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      &:after {
        position: absolute;
        left: 0;
        top: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border: 1px solid rgba(64, 87, 109, .1);
        border-radius: inherit;
        content: "";
        pointer-events: none;
        z-index: 2;
      }

      & > img {
        width: auto;
        height: 100%;
        object-fit: contain;
        outline: none;
        display: block;
        border-radius: inherit;
        opacity: 0;
        transition: opacity 0.5s ease;
        //animation: imageAnimation 1s ease forwards;
      }
    }

    .imageHidden {
      display: none;
    }

    & > .video {
      width: max-content;
      border-radius: inherit;
      box-sizing: border-box;
      transform-origin: center center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      &:focus {
        outline: none
      }

      & > video {
        width: auto;
        height: 100%;
        max-height: 100%;
        outline: none;
        display: block;
        object-fit: contain;
        border-radius: inherit;
        box-sizing: border-box;
      }
    }

    .hiddenImg {
      display: none;
    }

    .mask {
      position: absolute;
      overflow: hidden;
      width: 100%;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.4);
      bottom: 0;
      left: 0;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
    }

    &:hover {
      .mask {
        height: 48px;
        border-radius: 0 0 4px 4px;
      }
    }

    .maskBtnBox {
      position: absolute;
      top: 8px;

      .previewBtn {
        min-width: 76px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        margin-right: 14px;
        border: 1px solid #fff;
        display: inline-block;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        padding: 0 10px;
        border-radius: 6px;
        transition: all 0.3s;

        &:hover {
          color: #ff822c;
          background: #fff;
        }
      }

      .customizeBtn {
        min-width: 76px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        padding: 0 10px;
        border: 1px solid #ff822c;
        border-radius: 6px;
        background: #ff822c;
        transition: all 0.3s;

        &:hover {
          background: #e0640f;
          border: 1px solid #e0640f;
        }
      }
    }

  }

  & > .templateTitle {
    box-sizing: content-box;
    max-height: 33px;
    font-size: 16px;
    color: #17191d;
    padding: 12px 0 5px 0;
    font-weight: 400;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
  }
}

@keyframes imageAnimation {
  to {
    opacity: 1;
  }
}


@media screen and (max-width: 450px) {
  .itemBox {
    width: 355px;
    max-width: 400px;
    margin: 16px 0 0 16px;
    .slot_box {
      max-height: 200px;
    }
  }
}