.tabsBox {
  display: block;
  max-width: 100%;
  height: 32px;
  white-space: nowrap;
  overflow: auto;
  box-sizing: content-box;
  margin: 0 auto;
  position: relative;
  list-style: none;

  scrollbar-width: none; /* 在 Firefox 中隐藏滚动条 */
  -ms-overflow-style: none; /* 在 IE 和 Edge 中隐藏滚动条 */

  &::-webkit-scrollbar {
    display: none;
  }

  & > .tabsItem {
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    margin-left: 56px;
    box-sizing: border-box;
    height: inherit;
    line-height: inherit;

    cursor: pointer;
    user-select: none;
    color: rgba(26, 27, 28, 0.75);
    transition: all 0.3s;
    font-size: 17px;
    font-weight: 600;
    white-space: nowrap;
    &:hover {
      color: rgba(26, 27, 28, 1);
    }

    &:first-child {
      margin-left: 0;
    }
  }

  & > .tabsItemActive {
   color: #ff822c;
  }

  & > .animation {
    width: 0;
    height: 3px;
    background: #ff822c;
    border-radius: 2px;
    margin-top: 7px;
    position: absolute;
    left: 0;
    transition: all 0.3s ease 0s;
  }
}